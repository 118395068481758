<template>
  <v-avatar :size="size" color="teal">
    <span class="white--text">{{ strRole }}</span>
  </v-avatar>
</template>

<script>
export default {
  props: {
    role: {
      type: Number,
      default: null,
    },
    size: {
      type: Number,
      default: null,
    },
  },
  computed: {
    strRole() {
      switch (this.role) {
        case 2:
          return 'PM'
        case 3:
          return 'AM'
        case 4:
          return 'A'
        default:
          return 'U'
      }
    },
  },
}
</script>

<style scoped>
v-avator {
  padding: 4px;
}
</style>
