import { render, staticRenderFns } from "./Role.vue?vue&type=template&id=2761de5a&scoped=true&"
import script from "./Role.vue?vue&type=script&lang=js&"
export * from "./Role.vue?vue&type=script&lang=js&"
import style0 from "./Role.vue?vue&type=style&index=0&id=2761de5a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2761de5a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
installComponents(component, {VAvatar})
