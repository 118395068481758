<template>
  <v-container>
    <base-card>
      <div slot="heading" class="title font-weight-light">
        {{ $t('users.user_details') }}
      </div>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card class="mx-auto my-8">
            <v-card-title>{{ user.name }}</v-card-title>
            <v-card-text>
              <div class="subtitle-1">
                {{ user.email }}
              </div>
            </v-card-text>
            <v-divider class="mx-4" />
            <v-card-text>
              <div>
                <v-chip>{{ user.provider_id }}</v-chip>
                <v-chip v-if="user.is_admin" color="pink"> Admin </v-chip>
              </div>
            </v-card-text>
          </v-card>

          <v-card class="mt-2">
            <v-toolbar color="teal" dark>
              <v-toolbar-title>Belonging Organizations</v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <div>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" width="5%">ID</th>
                      <th width="5%">Role</th>
                      <th class="text-left" width="35%">
                        {{ $t('organizations.name') }}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(org, index) in user.organizations" :key="index">
                      <td>{{ org.id }}</td>
                      <td>
                        <Role :role="org.role" :size="34" />
                      </td>
                      <td>{{ org.name }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-card>
          <div class="mt-5">
            <v-btn
              v-if="canChangePassword"
              class="text-capitalize mr-2"
              @click="confirmChangePassword"
            >
              Change Password
            </v-btn>
            <v-btn
              v-if="user.provider_id == 'oidc.abeja-platform'"
              class="text-capitalize mr-2"
              @click="confirmSwitchAdmin"
            >
              Switch IsAdmin
            </v-btn>
            <v-btn class="text-capitalize" color="error" @click="confirmDeleteUser">
              {{ $t('common.delete') }}
            </v-btn>
          </div>
        </v-flex>
      </v-layout>
    </base-card>
    <v-dialog v-model="adminConfirm" persistent max-width="400">
      <v-card>
        <v-card-title>{{ $t('users.super_admin_confirm') }}</v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn color="teal" dark text @click="onCanceled()">
            {{ $t('common.cancel') }}
          </v-btn>
          <v-btn color="teal" dark @click="onAdminConfirmed()">
            {{ $t('common.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteConfirm" persistent max-width="400">
      <v-card>
        <v-card-title>{{ $t('users.delete_confirm') }}</v-card-title>
        <div class="px-3" v-if="user.provider_id == 'oidc.abeja-platform'">
          <v-alert prominent type="warning">
            {{ $t('users.platform_user_delete_caution') }}<br />
            <a class="text--lighten-5" :href="platformAdminURL" target="_blank">
              ABEJA Platform Admin Page
            </a>
          </v-alert>
        </div>
        <v-card-actions>
          <v-spacer />
          <v-btn color="teal" dark text @click="onCanceled()">
            {{ $t('common.cancel') }}
          </v-btn>
          <v-btn color="error" dark :loading="isDeleting" @click="onDeleteConfirmed">
            {{ $t('common.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="passwordConfirm" persistent max-width="600">
      <v-card>
        <v-card-title>{{ $t('users.change_user_password') }}</v-card-title>
        <v-form ref="passwordForm">
          <v-text-field
            v-model="password"
            autocomplete="new-password"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            label="Enter new password"
            :rules="[rules.required, rules.min]"
            :type="showPassword ? 'text' : 'password'"
            required
            counter
            class="password-input"
            @click:append="showPassword = !showPassword"
          />
          <v-card-actions>
            <v-spacer />
            <v-btn color="teal" dark text @click="onCanceled()">
              {{ $t('common.cancel') }}
            </v-btn>
            <v-btn color="teal" dark @click="onPasswordConfirmed()">
              {{ $t('common.save') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Role from '../components/Role.vue'
import BaseCard from '../components/common/BaseCard.vue'

// import Users from '../components/Users.vue';

export default {
  components: {
    Role,
    BaseCard,
  },
  data() {
    return {
      adminConfirm: false,
      deleteConfirm: false,
      passwordConfirm: false,
      password: '',
      showPassword: false,
      rules: {
        required: (value) => !!value || 'Required.',
        min: (v) => v.length >= 6 || 'Min 6 characters',
      },
      isDeleting: false,
    }
  },
  mounted() {
    const { userId } = this.$route.params
    this.fetchUserDetail(userId)
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
    canChangePassword() {
      return this.user.provider_id === 'password' && !this.user.email_verified
    },
    platformAdminURL() {
      if (process.env.VUE_APP_BASE_URL === 'https://api.annotation.abeja.io') {
        // production
        return 'https://admin.iam.abeja.io/users'
      }
      return 'https://admin.iam.stage.abeja.io/users'
    },
  },
  methods: {
    ...mapActions(['fetchUserDetail', 'updateUser', 'deleteUser']),
    confirmSwitchAdmin() {
      this.adminConfirm = true
    },
    confirmDeleteUser() {
      this.deleteConfirm = true
    },
    confirmChangePassword() {
      this.passwordConfirm = true
    },
    async onAdminConfirmed() {
      const data = {
        id: this.user.id,
        is_admin: !this.user.is_admin,
      }
      this.adminConfirm = false
      await this.updateUser(data)
      await this.fetchUserDetail(this.user.id)
    },
    async onDeleteConfirmed() {
      try {
        this.isDeleting = true
        await this.deleteUser(this.user.id)
        this.$snotify.success(this.$t('common.delete_successfully'))
        this.deleteConfirm = false
        this.$router.push('/users')
      } finally {
        this.isDeleting = false
      }
    },
    async onPasswordConfirmed() {
      const valid = this.$refs.passwordForm.validate()
      if (!valid) {
        return
      }
      const data = {
        id: this.user.id,
        password: this.password,
      }
      this.password = ''
      await this.updateUser(data)
      await this.fetchUserDetail(this.user.id)
      this.passwordConfirm = false
      this.$snotify.success(this.$t('common.save_successfully'))
    },
    onCanceled() {
      this.adminConfirm = false
      this.deleteConfirm = false
      this.passwordConfirm = false
    },
  },
}
</script>

<style scoped>
.organization-item {
  padding: 10px;
}
.organization-item-name {
  padding-left: 20px;
}
.password-input {
  margin: 10px;
}
</style>
